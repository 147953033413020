<template>
  <div class="ps-product ps-product--wide">
    <div class="ps-product__thumbnail">
      <router-link :to="'/product/' + product.slug">
        <img :src="`${product.images[0].path}`" alt="Product Image" />
      </router-link>
    </div>
    <div class="ps-product__container">
      <div class="ps-product__content">
        <router-link :to="'/product/' + product.id" class="ps-product__title">{{
          product.name
        }}</router-link>
        <p class="ps-product__vendor">
          Sold by:<router-link :to="'/shop/' + product.shop.slug">{{
            product.shop.name
          }}</router-link>
        </p>
        <ul class="ps-product__desc">
          <li>{{ product.description }}</li>
        </ul>
      </div>
      <div class="ps-product__shopping">
        <p class="ps-product__price">${{ product.price }}</p>
        <a class="ps-btn" @click.prevent="addToCart()">
          {{ loading ? "Adding..." : "Add To Cart" }}
          <i v-if="!loading" class="icon icon-right-arrow ml-1"></i>
          <div
            v-if="loading"
            class="spinner-border text-light"
            role="status"
          ></div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import global from "@/mixins/global";
export default {
  props: ["product"],
  data() {
    return {
      loading: false,
    };
  },
  mixins: [global],
  methods: {
    addToCart() {
      this.loading = true;
      let newProp = {
        // color: this.product.properties[0].color,
        // size: this.product.properties[0].size,
        qty: 1,
      };
      if (this.product.properties.hasOwnProperty("color")) {
        newProp["color"] = this.product.properties.color;
      }
      if (this.product.properties.hasOwnProperty("size")) {
        newProp["size"] = this.product.properties.size;
      }
      this.add(this.product, newProp);
      this.$notification.new("Product Added To Cart", { timer: 10 });

      setTimeout(() => {
        this.loading = false;
        location.reload();
      }, 2000);
      // $('html, body').animate({scrollTop:$('#mini-cart').position().top}, 'slow');
      document.getElementById("mini-cart").style.visibility = "visible";
      document.getElementById("mini-cart").style.opacity = "1";
      setTimeout(() => {
        document.getElementById("mini-cart").style.visibility = "hidden";
        document.getElementById("mini-cart").style.opacity = "0";
      }, 3000);
    },
  },
};
</script>
