<template>
  <div>
    <bread-crumb :name="'Shop'"></bread-crumb>

    <div class="ps-page--shop">
      <div class="ps-container">
        <div class="ps-shop-categories"></div>
        <div class="">
          <div class="">
            <aside class="widget widget_shop">
              <h4 class="widget-title">Categories</h4>
              <ul class="ps-list--categories">
                <li
                  v-for="shopCategory in shop.categories"
                  :key="shopCategory.id"
                  class="menu-item-has-children row justify-content-between pl-4 pr-4"
                >
                  {{ shopCategory.name }}
                  <input
                    class="form-check"
                    type="checkbox"
                    :id="'filter_category_' + shopCategory.id"
                    @change="updateCategoryFilters(shopCategory.id)"
                    name="filter_category"
                  />
                </li>
              </ul>
            </aside>
          </div>
          <div class="mx-3">
            <HomeStoreFront6></HomeStoreFront6>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BreadCrumb from "../../components/Navs/BreadCrumb.vue";
import ShopCategoryCard from "../../components/Cards/ShopCategoryCard.vue";
import ProductCard from "../../components/Cards/ProductCard.vue";
import ListProductCard from "../../components/Cards/ListProductCard.vue";
import HomeStoreFront6 from "../../views/pages/Home/HomeStoreFront6.vue";
import { mapState } from "vuex";
export default {
  components: { BreadCrumb, ShopCategoryCard, ListProductCard, ProductCard,HomeStoreFront6 },
  data() {
    return {
      selectedCategory: "All",
      products: [],
      shopCategory: {},
      links: {},
      meta: {},
      url: null,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1,
      selectedCategories: [],
      isLoading: true,
      fullPage: false,
      show: false,
      categoryFilters: [],
      catFilterString: "",
    };
  },
  computed: {
    ...mapState({
      shop: (state) => state.shop,
    }),
  },
  mounted() {
    this.updateCategoryFilters("");
    //this.getProductsV2();
  },
  methods: {
    updateCategoryFilters(cat_id) {
      var cat_index = this.categoryFilters.indexOf(cat_id);
      if (cat_index > -1) {
        //It exists in the array
        //Remove it from the array
        this.categoryFilters.splice(cat_index, 1);
      } else if (cat_id) {
        // It doesnt exist in the array
        // Adde the item to the array
        this.categoryFilters.push(cat_id);
      }

      // Update filters string
      this.catFilterString = "";
      //alert(this.categoryFilters.length);
      if (this.categoryFilters.length === 0) {
        //Add All Categories if nothing is selected
        this.shop.categories.forEach((item, index) => {
          this.catFilterString = this.catFilterString + item.id;
          if (index !== this.categoryFilters.length - 1) {
            this.catFilterString = this.catFilterString + "|";
          }
        });
      } else {
        //If we have at least 1 selected
        this.categoryFilters.forEach((item, index) => {
          this.catFilterString = this.catFilterString + item;
          if (index !== this.categoryFilters.length - 1) {
            this.catFilterString = this.catFilterString + "|";
          }
        });
      }

      // Get the Products
      this.getProductsV2();
    },

    addCategoryFilter(categoryId) {
      if (this.selectedCategories.indexOf(categoryId) >= 0) {
        this.selectedCategories = this.selectedCategories.filter(
          (catId) => categoryId !== catId
        );
      } else {
        this.selectedCategories.push(categoryId);
      }
      this.getProducts();
    },

    selectCategory(name) {
      this.selectedCategory = name;
    },

    getProducts() {
      this.$loading(false);
      let queryParams = [];
      if (this.selectedCategories.length > 0) {
        queryParams.push(
          "shops/categories=" + this.selectedCategories.join(",")
        );
      }
      let url = "products";
      if (this.url) {
        url = this.url;
      }

      if (queryParams.length > 0) {
        if (url.indexOf("?") < 0) {
          url = url + "?" + queryParams.join("&");
        } else {
          url = url + "&" + queryParams.join("&");
        }
      }
      this.isLoading = true;
      axios
        .get(url)
        .then((response) => {
          this.products = response.data.data;
          this.links = response.data.links;
          this.meta = response.data.meta;
          this.count = response.data.meta.total;
          this.pageNumber = response.data.meta.current_page;
          this.handleNextPage(response.data.links);
          this.handlePrevPage(response.data.links);
          this.isLoading = false;
          this.$loading(false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$loading(false);
        });
    },

    getProductsV2() {
      this.$loading(false);
      this.isLoading = true;
      axios
        .get("products?category=" + this.catFilterString)
        .then((response) => {
          this.products = response.data.data;
          this.links = response.data.links;
          this.meta = response.data.meta;
          this.count = response.data.meta.total;
          this.pageNumber = response.data.meta.current_page;
          this.handleNextPage(response.data.links);
          this.handlePrevPage(response.data.links);
          this.isLoading = false;
          this.$loading(false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$loading(false);
        });
    },

    addToCart(product, prop) {
      let newProp = {
        // color: prop.color,
        // size: prop.size,
        qty: 1,
      };
      if (product.properties.hasOwnProperty("color")) {
        newProp["color"] = product.properties.color;
      }
      if (product.properties.hasOwnProperty("size")) {
        newProp["size"] = product.properties.size;
      }
      this.add(product, newProp);
    },
    scrollTop() {
      let element = document.getElementsByClassName("ps-shopping-product");
      if (element.length > 0) {
        const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
    },
    goToNextPage: function() {
      this.url = this.nextPage;
      if (this.url) {
        this.getProducts()
      }
      this.scrollTop();
    },

    goToPrevPage: function() {
      this.url = this.prevPage;
      if (this.url) {
        this.getProducts()
      }
      this.scrollTop();
    },

    handleNextPage(links) {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false;
      }
    },

    handlePrevPage(links) {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false;
      }
    },
  },
};
</script>
<style lang="css" scoped>
.sidebar {
  height: 700px;
}
.card-title {
  color: black;
}
.price {
  margin-left: 40px;
}

.card-frame > .card-overlay {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  border-radius: 5px;
}

.card-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 5rem 6rem;
  background-color: #F1F1F1; */
}

@media screen and (max-width: 768px) {
  .card-section {
    padding: 3rem 3rem;
  }

  .search-input {
    width: 500px;
  }
}

@media screen and (max-width: 575px) {
  .card-section {
    padding: 0rem;
    padding-top: 4rem;
  }
  .search-input {
    width: 500px;
  }
}

.card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .card-container {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 575px) {
  .card-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    row-gap: 0.8rem;
  }
}

.card-frame {
  height: 25rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
}

@media screen and (max-width: 575px) {
  .card-frame {
    height: 10rem;
  }
}

.card-frame[card="fast"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/fast_food.webp");
}

.card-frame[card="fast"] > .card-overlay {
  background-color: rgba(70, 3, 3, 0.6);
}

.card-frame[card="pharmacy"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/pharmacy.webp");
}

.card-frame[card="pharmacy"] > .card-overlay {
  background-color: rgba(60, 3, 70, 0.6);
}

.card-frame[card="electronics"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/electronics.webp");
}

.card-frame[card="electronics"] > .card-overlay {
  background-color: rgba(13, 70, 3, 0.6);
}

.card-frame[card="grocery"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/grocery.webp");
}

.card-frame[card="grocery"] > .card-overlay {
  background-color: rgba(70, 47, 3, 0.6);
}

.card-frame[card="hair_beauty"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/hair_beauty.webp");
}

.card-frame[card="hair_beauty"] > .card-overlay {
  background-color: rgba(70, 3, 3, 0.5);
}

.card-frame[card="house_home"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/house.webp");
}

.card-frame[card="house_home"] > .card-overlay {
  background-color: rgba(38, 95, 109, 0.6);
}

.card-frame[card="construction"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/construction.webp");
}

.card-frame[card="construction"] > .card-overlay {
  background-color: rgba(70, 47, 3, 0.6);
}

.card-frame[card="hardware"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/hardware.webp");
}

.card-frame[card="hardware"] > .card-overlay {
  background-color: rgba(6, 69, 153, 0.6);
}

.card-frame[card="vehicles"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/vehicles.webp");
}

.card-frame[card="vehicles"] > .card-overlay {
  background-color: rgba(211, 42, 42, 0.6);
}

.card-frame[card="solar"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/solar.webp");
}

.card-frame[card="solar"] > .card-overlay {
  background-color: rgba(23, 167, 131, 0.6);
}

.card-frame[card="entertainment"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/entertainment.webp");
}

.card-frame[card="entertainment"] > .card-overlay {
  background-color: rgba(238, 48, 136, 0.6);
}

.card-frame[card="funeral"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/funeral.webp");
}

.card-frame[card="funeral"] > .card-overlay {
  background-color: rgba(63, 68, 65, 0.6);
}

.card-frame[card="gifts"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/treats.webp");
}

.card-frame[card="gifts"] > .card-overlay {
  background-color: rgba(201, 58, 77, 0.6);
}

.card-heading {
  font-size: 1.7em;
  text-align: center;
  padding: 2.5rem 0;
  padding-top: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .card-heading {
    padding: 1.5rem 0;
  }
}

.card-title {
  color: #ffffff;
  font-size: 1.2em !important;
  margin: 0;
  margin-bottom: 0.75rem;
}

@media screen and (max-width: 575px) {
  .card-title {
    font-size: 1em;
  }
}

.card-text {
  padding: 2rem;
  padding-right: 0.5rem;
  flex: 0 0 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
}

.card-text > p {
  margin: 0;
  padding: 0;
  font-size: 0.96em;
  margin: auto 0;
  color: #fff;
}

@media screen and (max-width: 575px) {
  .card-text > p {
    display: none;
  }
}

.card-text > .btn-primary {
  padding: 10px;
  min-width: 130px;
  font-size: 15px;
}

@media screen and (max-width: 575px) {
  .card-text {
    flex: 1;
    padding: 0.7rem 1rem;
    justify-content: space-between;
  }
}

.card-logo {
  background-color: #fff;
  position: relative;
  flex: 1;
  -webkit-clip-path: polygon(40% 0, 103% 0%, 102% 100%, 2% 100%);
  clip-path: polygon(40% 0, 103% 0%, 102% 100%, 2% 100%);
  height: 100%;
}

.card-logo > img {
  max-width: 60%;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

@media screen and (max-width: 575px) {
  .card-logo {
    display: none;
  }
}

.card-paragraph {
  padding: 0 !important;
}
</style>